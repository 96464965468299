<template>
  <div class="settings-privacy">
    <!-- Change password form -->
    <v-form
      v-model="changePassword.formValid"
      class="mb-5"
      ref="changePasswordForm"
      @submit.prevent="updatePassword"
    >
      <div>
        <!-- Current password -->
        <password-input
          v-model="changePassword.formData.old_password"
          :label="$t('users.currentPassword')"
          :rules="[$rules.required]"
          filled
          background-color="ocean-dark"
          class="mt-6"
        />

        <!-- New password -->
        <password-input
          v-model="changePassword.formData.new_password"
          :label="$t('users.newPassword')"
          :rules="[$rules.required, $rules.password]"
          filled
          background-color="ocean-dark"
        />

        <!-- Repeat password -->
        <password-input
          v-model="changePassword.formData.password_confirmation"
          :label="$t('users.confirmNewPassword')"
          :rules="[
            $rules.required,
            $rules.same(
              $t('users.newPassword'),
              changePassword.formData.new_password
            ),
          ]"
          filled
          background-color="ocean-dark"
        />
      </div>

      <api-response :response="changePassword.formResponse" />

      <div class="text-right">
        <v-btn
          color="primary"
          :disabled="!changePassword.formValid"
          :loading="$store.getters['auth/loading'].changePassword"
          type="submit"
          rounded
        >
          {{ $t("users.changePassword") }}
        </v-btn>
      </div>
    </v-form>

    <!-- Account settings -->
    <div v-if="settings" class="account-settings">
      <!-- Private account -->
      <v-checkbox
        v-model="settings.profile_type"
        class="mt-0"
        :label="$t('settings.privateProfile')"
        true-value="private"
        false-value="public"
        @change="(v) => updateSettings('profile_type', v)"
      />

      <!-- Two factor -->
      <v-checkbox
        v-model="settings.two_factor_auth"
        class="mt-0"
        :label="$t('settings.twoFactor')"
        :true-value="1"
        :false-value="0"
        @change="(v) => updateSettings('two_factor_auth', v)"
      />
    </div>

    <!-- Download user data -->
    <div class="download-user-data text-right">
      <v-btn
        :to="{ path: '#download-user-data' }"
        @click.prevent="requestUserData"
        color="secondary"
        rounded
        small
        class="download__user_data"
      >
        <v-icon right dark class="mr-2">mdi-account</v-icon>
        {{ $t("settings.downloadUserData") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    changePassword: {
      formData: {},
      formValid: false,
      formResponse: null,
    },
  }),

  computed: {
    settings() {
      return this.$user.account_settings;
    },
  },

  created() {
    if (this.$route.query.downloadUserData) {
      this.downloadUserData();
    }
  },

  methods: {
    updateSettings(key, value) {
      const payload = {};
      payload[key] = value;

      this.$store
        .dispatch("auth/updateAccountSettings", payload)
        .then(() => {
          this.$toast.success(this.$t("settings.updated"));
        })
        .catch(() => {
          this.$toast.error("Error while updating account settings.");
        });
    },

    updatePassword() {
      this.changePassword.formResponse = null;
      this.$store
        .dispatch("auth/changePassword", this.changePassword.formData)
        .then((data) => {
          this.$toast.success(data.message);
          this.$refs.changePasswordForm.reset();
        })
        .catch((err) => {
          this.changePassword.formResponse = err.data;
        });
    },

    requestUserData() {
      if (this.$loading("auth/requestUserData")) {
        return;
      }

      this.$store
        .dispatch("auth/requestUserData")
        .then((resp) => {
          this.$toast.success(resp.message);
        })
        .catch((res) => {
          this.$toast.error(res.message || res.error || "Error");
        });
    },

    downloadUserData() {
      if (this.$loading("auth/downloadUserData")) {
        return;
      }

      this.$store
        .dispatch("auth/downloadUserData", this.$route.query.downloadUserData)
        .catch(() => {
          this.$toast.error("Error while downloading user data.");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
