var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings-privacy"},[_c('v-form',{ref:"changePasswordForm",staticClass:"mb-5",on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}},model:{value:(_vm.changePassword.formValid),callback:function ($$v) {_vm.$set(_vm.changePassword, "formValid", $$v)},expression:"changePassword.formValid"}},[_c('div',[_c('password-input',{staticClass:"mt-6",attrs:{"label":_vm.$t('users.currentPassword'),"rules":[_vm.$rules.required],"filled":"","background-color":"ocean-dark"},model:{value:(_vm.changePassword.formData.old_password),callback:function ($$v) {_vm.$set(_vm.changePassword.formData, "old_password", $$v)},expression:"changePassword.formData.old_password"}}),_c('password-input',{attrs:{"label":_vm.$t('users.newPassword'),"rules":[_vm.$rules.required, _vm.$rules.password],"filled":"","background-color":"ocean-dark"},model:{value:(_vm.changePassword.formData.new_password),callback:function ($$v) {_vm.$set(_vm.changePassword.formData, "new_password", $$v)},expression:"changePassword.formData.new_password"}}),_c('password-input',{attrs:{"label":_vm.$t('users.confirmNewPassword'),"rules":[
          _vm.$rules.required,
          _vm.$rules.same(
            _vm.$t('users.newPassword'),
            _vm.changePassword.formData.new_password
          ),
        ],"filled":"","background-color":"ocean-dark"},model:{value:(_vm.changePassword.formData.password_confirmation),callback:function ($$v) {_vm.$set(_vm.changePassword.formData, "password_confirmation", $$v)},expression:"changePassword.formData.password_confirmation"}})],1),_c('api-response',{attrs:{"response":_vm.changePassword.formResponse}}),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.changePassword.formValid,"loading":_vm.$store.getters['auth/loading'].changePassword,"type":"submit","rounded":""}},[_vm._v(" "+_vm._s(_vm.$t("users.changePassword"))+" ")])],1)],1),(_vm.settings)?_c('div',{staticClass:"account-settings"},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":_vm.$t('settings.privateProfile'),"true-value":"private","false-value":"public"},on:{"change":(v) => _vm.updateSettings('profile_type', v)},model:{value:(_vm.settings.profile_type),callback:function ($$v) {_vm.$set(_vm.settings, "profile_type", $$v)},expression:"settings.profile_type"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":_vm.$t('settings.twoFactor'),"true-value":1,"false-value":0},on:{"change":(v) => _vm.updateSettings('two_factor_auth', v)},model:{value:(_vm.settings.two_factor_auth),callback:function ($$v) {_vm.$set(_vm.settings, "two_factor_auth", $$v)},expression:"settings.two_factor_auth"}})],1):_vm._e(),_c('div',{staticClass:"download-user-data text-right"},[_c('v-btn',{staticClass:"download__user_data",attrs:{"to":{ path: '#download-user-data' },"color":"secondary","rounded":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.requestUserData.apply(null, arguments)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"right":"","dark":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.$t("settings.downloadUserData"))+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }